@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import '~leaflet/dist/leaflet.css';
@import '~react-leaflet-markercluster/dist/styles.min.css';
.main_container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  transform: translate(-50%, -50%);
  z-index: -1;  
}
.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
} 
.content-1{
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  border-radius: 15px;
  /* align-items: center; */
}
.header_Container {
  /* padding: 20px 10px 0px 10px; */
}
.header_logo {
  width: 120px;
}
.headerLink {
  color: #000000;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-right: 46px;
}
.headerLink:hover {
  color: #000000;
}
.headerLinkActive {
  color: #22762b;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: 1px solid #22762b;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  margin-right: 46px;
}
.navitem-link {
  color: #22762b;
  text-decoration: none;
}
.navitem-link:hover {
  color: #22762b;
}
.headerLinkActive:hover {
  color: #22762b;
}
.navbar-user-profile{
  width: 35px;
transition: 0.3s ease;
}
.default_profile_img {
  width: 30px;
}
.home_left_section {
  display: flex;
  flex-direction: column;
}
.home_right_section {
  display: flex;
  flex-direction: column;
}
.homeButton {
  border-radius: 32.5px;
  background: radial-gradient(253.97% 95.38% at 26% 23.85%, #22762B 0%, #9FDA64 100%);
  box-shadow: -6px -11px 59.3px 0px rgba(255, 215, 72, 0.40) inset, 13px 9px 50.9px 0px rgba(34, 118, 43, 0.70) inset, 10px 14px 31.8px 0px rgba(34, 118, 43, 0.30);
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border-radius: 20px;
  border: none;
  width: 130px;
  padding: 7px 20px 7px 20px;
  margin-top: 25px;
  margin-left: auto;
  z-index: 100;
  transition: 0.3s ease;
}
.homeButtonright {
  border-radius: 32.5px;
  background: radial-gradient(253.97% 95.38% at 26% 23.85%, #22762B 0%, #9FDA64 100%);
  box-shadow: -6px -11px 59.3px 0px rgba(99, 95, 81, 0.4) inset, 13px 9px 50.9px 0px rgba(34, 118, 43, 0.70) inset, 10px 14px 31.8px 0px rgba(34, 118, 43, 0.30);
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border-radius: 20px;
  border: none;
  width: 130px;
  padding: 7px 20px 7px 20px;
  margin-top: 25px;
  margin-left: 0;
  z-index: 100;
  transition: margin-left 0.3s ease-in; /* Added transition property */
}
.activeExploreButton{
  margin-right: -100px;
}
.activeExploreButtonRight{
  margin-left: -100px !important;
}
.home_main_section{
  height: 80vh;
}
.homeButton:focus {
  box-shadow: none;
  outline: none;
  border: none;
}
.homeButton:focus-visible {
  box-shadow: none;
  outline: none;
  border: none;
}
.home_mid_section_container {
  display: flex;
  justify-content: center;
}
.home_mid_section {
  height: 380px;
  display: flex;
  align-items: center;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  width: 380px;
  background-image: url("./assets/home/test.jpg");
  background-size: cover; /* This will cover the entire background */
  background-position: center;
  z-index: 1;
  border-radius: 200px;
  box-shadow: rgba(34, 118, 43, 0.4) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.home_mid_section_1{
  height: 380px;
  display: flex;
  align-items: center;
  align-items: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  width: 380px;
  background-size: cover; /* This will cover the entire background */
  background-position: center;
  z-index: 1;
  border-radius: 200px;
  box-shadow: rgba(34, 118, 43, 0.4) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.home_mid_section_bg_1{
  margin-bottom: 20px;
}

.science-main-row{
  height: 73vh;
  align-items: flex-end;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x:hidden;
  width: 100vw;
}
.science-comp-image{
  height: 140px;
  width: 146px;
  object-fit: cover;
  border-radius: 20px;
  margin-top: 23px;
}
.science-comp-image-hovered{
  height: 140px;
  width: 146px;
  object-fit: cover;
  border-radius: 20px;
  margin-right: 5px;
}
.science-card-hovered{
  display: none;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin-top: 20px;
background: #D5E9D7;
box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);
}
.science-card-desc{
  color: #000;
font-family: Montserrat;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 5px;
}
.science-comp-text{
  color: #22762B;
  margin-top: auto;
font-family: Montserrat;
font-size: 23px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.science-comp-desc{
  color: #000;
font-family: Montserrat;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.science-comp-button-updated{
  font-size: 10px;
  border-radius: 50px;
  width: 146px;
border: 1px solid #FFF;
background: #22762B;
margin-top: 5px;
box-shadow: 6px 8px 12.4px 0px rgba(0, 0, 0, 0.15), -26px -18px 39.9px 0px rgba(0, 0, 0, 0.25) inset, 19px 27px 55.4px 0px rgba(0, 0, 0, 0.25) inset;
}
.science-comp-button-updated:hover{
border: 1px solid #FFF;
background: #22762B;
/* margin-top: 10px; */
box-shadow: 6px 8px 12.4px 0px rgba(0, 0, 0, 0.15), -26px -18px 39.9px 0px rgba(0, 0, 0, 0.25) inset, 19px 27px 55.4px 0px rgba(0, 0, 0, 0.25) inset;
}
.science-comp-column{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.science-comp-column:hover .science-card-hovered{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
background: #D5E9D7;
box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);
padding: 10px 15px 10px 15px;
/* margin-top: 10px; */
z-index: 1;
}
.science-card-not-hovered{
  display: flex;
  flex-direction: column;
}
.science-comp-column:hover .science-card-not-hovered{
  display: none;
}
.home_mid_section-1{
  height: 380px;
  display: flex;
  align-items: center;
  align-items: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  width: 380px;
  background-size: cover; /* This will cover the entire background */
  background-position: center;
  z-index: 1;
  border-radius: 200px;
  box-shadow: rgba(34, 118, 43, 0.4) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.activeSection_1{
  background-image: url("./assets/home/nFold.jpg");
}
.activeSection_2{
  background-image: url("./assets/home/activities.png");
}
.activeSection_3{
  background-image: url("./assets/home/nFilms.png");
}
.activeSection_4{
  background-image: url("./assets/home/compaigns.png");
}
.activeSection_5{
  background-image: url("./assets/home/nAct.png");
}
.activeSection_6{
  background-image: url("./assets/home/gapTable.png");
}

.home-mid-section-text_1{
  font-family: "Bebas Neue";
  font-size: 19px;
  letter-spacing: 0.05rem;
  padding: 2px 30px;
  color: white;
}
.home-mid-section-text-box{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dynamic-counter{
  width: 100px;
  margin-top: 40px;
  margin-bottom: 10px;
}
.dynamic-counter p{
  color:white;
  font-family: "Bebas Neue";
  margin: auto;
  /* line-height: 0.01rem; */
}
.home-mid-section-text{
  font-family: "Bebas Neue";
  font-size: 19px;
  letter-spacing: 0.05rem;
  padding: 2px 42px;
  color: white;
}
.counter-title{
  position: absolute;
  opacity: 1 !important;
  top:19%;
  font-size: 14px;
}
.dyanmic-counter-count-current{
   font-size: 22px;
   border: 1px solid #6f6c6c;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 40px;
   padding-top: 2px;
}
.dyanmic-counter-count-current p{
  font-size: 26px;
}
.dyanmic-counter-count-prev{
  font-size: 26px;
  border: 1px solid #6f6c6c;;
  display: flex;
  border-top: none;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-top: 5px;
}
.signIn-button{
  color: #000;
  margin-top: auto;
  margin-bottom: auto;
text-align: center;
font-family: Montserrat;
font-size: 11px;
padding: 5px 9px;
font-style: normal;
font-weight: 600;
cursor: pointer !important;
line-height: normal;
  border-radius: 32.5px;
border: 1px solid #000;
background: radial-gradient(253.97% 95.38% at 26% 23.85%, #D2D2D2 0%, #FFF 100%);
box-shadow: -6px -11px 59.3px 0px rgba(255, 255, 255, 0.40) inset, 13px 9px 50.9px 0px rgba(196, 240, 201, 0.70) inset, 4px 3px 10.8px 0px rgba(34, 118, 43, 0.30);
}
.dyanmic-counter-count-prev p{
  opacity: 0.4;
}
.dyanmic-counter-count-next{
  font-size: 26px;
  border: 1px solid #6f6c6c;
  display: flex;
  border-bottom: none;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-top: 5px;
}
.dyanmic-counter-count-next p{
  opacity: 0.5;
}
.home_main_section {
  height: 73vh;
  margin-left: 30px;
  align-items: center;
}
.footer-container {
  margin-top: auto;
  background-color: black;
  padding: 15px 15px 15px 15px;
}
.footer-cont {
  margin-left: 0px;
  margin-right: 0px;
}
.footer-icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 100px;
}
.footer-icon {
  margin-right: 35px;
}
.footer-icon-img {
  width: 20px;
}
.footer-links-left {
  color: white;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  line-height: 0.45rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer-links-left p {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding: 10px;
}
.footer-links-right {
  color: white;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  line-height: 0.45rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.footer-links-right p {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding: 10px;
  width: fit-content;
}
.login-section-row {
  margin-left: 30px;
  align-items: center;
  justify-content: center;
}
.login-section-container {
  padding: 10px 30px;
  /* margin-top: 30px; */
  height: 73vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-section-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #079015;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 20px;
  padding: 10px 30px;
}
.login_text_heading {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}
.login-pin-input {
  margin-top: 25px;
  border-radius: 25px;
  border: 1.35px solid #079015;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  text-align: center;
}
.login-pin-input:focus {
  border: 1.35px solid #079015;
  box-shadow: none;
  outline: none;
}
.login-pin-input::placeholder {
  font-family: "Montserrat", sans-serif;
  color: #a8a8a8;
  font-size: 13px;
  font-weight: 500;
}
.edit-profile-image{
  position: absolute;
  left: 46%;
  top: 80%;
  width: 12px;
}
.mob-edit-profile-image{
  position: absolute;
  left: 46%;
  top: 85%;
  width: 12px;
}
.dashboard-nav-tabs{
  font-size: 15px;
  font-weight: 600;
}
.profile-input{
  height: 30px;
  text-align: start;
  width:200px;
  padding-top: 0px !important;
  margin-top: 12px !important;
}
.mob-profile-input{
  height: 30px;
  border-radius: 5px !important;
  text-align: start;
  width: 100%;
  margin-top: 15px !important;
  padding: 20px;
}
.mob-profile-textarea{
  margin-top: 12px;
  margin-top: 15px !important;
  width: 100%;
  border-radius: 5px !important;
  height: 60px !important;
  padding: 20px !important; 
}
.mob-profile-edit-window{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  /* align-items: center; */
}
.css-hmpsd3-control{
  min-height: 30px !important;
}
.css-hmpsd3-control:focus{
  min-height: 30px !important;
}

.profile-input::placeholder{
  font-family: "Montserrat", sans-serif;
  color: #000 !important;
  padding-left: 10px;
  font-size: 11px;
}
.profile-textarea{
  margin-top: 12px;
  border-radius: 15px !important;
  height: 60px !important;
  width: 200px !important;
  margin-bottom: -6px;
}
.profile-textarea::placeholder{
  color:#000 !important;
  text-align: start;
  padding-left: 10px;
  font-family: "Montserrat", sans-serif;
}
.react-select__css-1fdsijx-ValueContainer {
  padding: 0;
}

.login-submit {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 15px;
  border: none;
  width: 180px;
  margin-top: 25px;
  border-radius: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: radial-gradient(#22762b, #76a844);
}
.login-submit:focus {
  box-shadow: none;
  outline: none;
  border: none;
}
.login-forgot-password {
  font-family: "Montserrat", sans-serif;
  color: #22762b;
  font-weight: 600;
  font-size: 13px;
  margin-top: 30px;
}
.login-new-user-register {
  font-family: "Montserrat", sans-serif;
  color: #22762b;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}

.science-comp-container {
  width: 220px;
  padding: 5px;
  margin-right: -13px;
  height: 365px;
  border: 2px solid #fff;
  border-radius: 35px;
  box-shadow: 13px 20px 25.4px rgba(34, 118, 43, 0.3);
  backdrop-filter: blur(7.5px);
  background: radial-gradient(
    97.74% 97.74% at 24.94% 9.33%,
    rgba(255, 245, 209, 0.7) 0%,
    rgba(159, 218, 100, 0.6) 50.5%,
    rgba(245, 238, 57, 0.5) 100%
  );
  transform: scale(0.85); /* Initial scaled-down state */
    transition: transform 0.3s ease; 
}
.science-comp-display-picture {
  width: 100%;
  height: 60%;
  border-radius: 35px;
  border: 2px solid #fff;
  object-fit: cover;
}
.science-comp-desc-text {
  margin-top: 12px;
  margin-left: 5px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-size: 0.665rem;
}
.science-main-comp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 74vh;
}
.science-main-container {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.science-main-container:::-webkit-scrollbar {
  display: none;
}
.science-comp-button {
  border: none;
  position: absolute;
  bottom: -15px; /* Adjust this value to control how much of the button is outside the card */
  left: 50%;
  transform: translateX(-50%);
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  border-radius: 15px;
  font-size: 10px;
  width: 120px;
  padding: 4px 10px;
  background: radial-gradient(
      82.6% 253.78% at 26% 23.85%,
      #22762b 0%,
      #9fda64 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  box-shadow: 10px 14px 31.8px rgba(34, 118, 43, 0.3),
    inset -6px -11px 59.3px rgba(255, 215, 72, 0.4),
    inset 13px 9px 50.9px rgba(34, 118, 43, 0.7);
}
.science-comp-container:hover {
  transition: transform 0.2s;
  z-index: 1000;
  transform: scale(1);
}
.science-card {
  height: 300px;
}
.team-page-title {
  color: #22762b;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  /* border: 0.02rem solid #22762b; */
  border-radius: 7px;
border: 0.02rem solid #22762B;
box-shadow: 1px 1px 5.6px 0px rgba(34, 118, 43, 0.25);
  padding: 6px 8px 6px 8px;
  margin-right: 15px;
}

.team-member-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Ensure this is the containing block */
  margin-right: 50px;
}

.ncount-journey-description{
  font-size: 13px;
  font-family: Montserrat;
  
}
.team-image-box {
  position: relative;
  margin-bottom: -30px;
  box-sizing: border-box;
  border: 2px solid #ffffff;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(9px 10px 25.6px rgba(34, 118, 43, 0.25));
  border-radius: 15px;
  z-index: 10;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  margin-right: 70px;
}

.team-image {
  border: 0.07rem solid #037711;
  height: 70px;
  object-fit: cover;
  width: 70px;
  filter: drop-shadow(9px 10px 25.6px rgba(34, 118, 43, 0.25));
  border-radius: 10px;
}

.team-desc-box {
  z-index: 0;
  background: linear-gradient(
    127.79deg,
    rgba(34, 118, 43, 0.3) 23.62%,
    rgba(255, 255, 255, 0.3) 127.09%
  );
  border: 2px solid #ffffff;
  box-shadow: 1px 1px 25.6px rgba(34, 118, 43, 0.25);
  border-radius: 15px;
  width: 110px;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.4s, width 0.4s, height 0.4s;
  position: absolute; /* Relative to the containing block */
  overflow: hidden;
  transform-origin: top center; /* Pivot from the top center */
  padding-top: 30px; /* Ensure space for the image */
}

/* .team-desc-box:hover {
  width: 220px;
  height: 190px;
  z-index: 1; 
  box-shadow: 1px 1px 25.6px rgba(34, 118, 43, 0.25);
backdrop-filter: blur(10px);
background: rgba(135, 205, 142, 0.8);
} */

.team-member-desc-box {
  margin-top: 30px;
  display: flex;
  z-index: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-member-title {
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin: 0px !important;
}

.team-member-desc {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 500;
}

.team-member-info {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 500;
  width: 150px;
  opacity: 0;
  height: 0;
  transition: opacity 0.4s ease-in-out, height 0.4s ease-in-out;
  overflow: hidden;
}

/* .team-desc-box:hover .team-member-info {
  opacity: 1;
  height: auto;
  margin-left: 0px !important;
  padding-left: 0px !important;
} */
/* .team-desc-box:hover .team-member-desc-box{
  display: flex;
  z-index: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  margin-left: 5px !important;
} */
 
  .team-page-heading{
    margin: 30px 0px 20px 0px;
  }
  .all-team-members-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    margin-top: 70px;
  }
  
  .team-member-box-partner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; /* Ensure this is the containing block */
    margin-right: 50px;
  }
  
  .team-image-box-partner {
    position: relative;
    margin-bottom: -30px;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    z-index: 10;
    top: -46px;
    left: 50%;
    transform: translateX(-50%);
    margin-right: 70px;
  }
  
  .team-image-partner {
    height: 120px;
    width: 120px;
    filter: drop-shadow(9px 10px 25.6px rgba(34, 118, 43, 0.25));
    border-radius: 10px;
  }
  
  .team-desc-box-partner {
    z-index: 0;
    background: linear-gradient(
      127.79deg,
      rgba(34, 118, 43, 0.3) 23.62%,
      rgba(255, 255, 255, 0.3) 127.09%
    );
    border: 2px solid #ffffff;
    box-shadow: 1px 1px 25.6px rgba(34, 118, 43, 0.25);
    border-radius: 15px;
    width: 140px;
    height: 125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.4s, width 0.4s, height 0.4s;
    position: absolute; /* Relative to the containing block */
    overflow: hidden;
    transform-origin: top center; /* Pivot from the top center */
    padding-top: 30px; /* Ensure space for the image */
  }
/*     */
  
  .team-member-desc-box-partner {
    margin-top: 30px;
    display: flex;
    z-index: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .team-member-title-partner {
    font-size: 11px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin: 0px !important;
  }
  
  .team-member-desc-partner {
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-weight: 500;
  }
  
  .team-member-info-partner {
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-weight: 500;
    width: 150px;
    opacity: 0;
    height: 0;
    transition: opacity 0.4s ease-in-out, height 0.4s ease-in-out;
    overflow: hidden;
  }
.team-page-heading-partner{
      margin: 30px 0px 20px 0px;
    }
.all-team-members-container-partner{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 50px;
      margin-top: 70px;
    }
.main-Team-Component{
  overflow-y: scroll;
  padding-bottom: 20px;
}
.main-Team-Component::-webkit-scrollbar {
  display: none;
}
.profile-section{
  overflow: scroll;
  border-radius: 15px;
border: 2px solid #FFF;
height: 85vh;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
background: linear-gradient(157deg, rgba(34, 118, 43, 0.30) 14.59%, rgba(255, 255, 255, 0.30) 80.66%);
box-shadow: 1px 1px 25.6px 0px rgba(34, 118, 43, 0.25);
}
.profile-section::-webkit-scrollbar{
  display: none;
}
.Dashboard-main-container{
  overflow: hidden;
}
.profile-image-circle{
width: min-content;
  border-radius: 109.5px;
border: 1px solid #22762B;
background: #FFF;
margin-top: 10px;
padding: 5px;
box-shadow: 5px 2px 12px 0px rgba(34, 118, 43, 0.20);
}
.profile-image{
  width: 85px;
  height: 85px;
  border-radius: 50px;
}
.profile-update-button{
  border-radius: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
border: 1px solid #22762B;
background: #22762B;
font-size: 13px;
padding-left: 15px;
padding-right: 15px;
}
.mob-profile-update-button{
  border-radius: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
border: 1px solid #22762B;
background: #22762B;
font-size: 15px;
color: white;
padding:10px 35px;
}

.profile-update-button:hover{
  border: 1px solid #22762B;
  background: #22762B; 
}
.profile-info{
  border-radius: 20px;
  width: 200px;
  margin-top: 5px;
  border: 1px solid #22762B;
  border-bottom-color: 1px transparent;
}
.profile-name-info{
  padding:5px 10x 5px 5px;
  color: #000;
  margin-top: 5px;
text-align: center;
font-family: Montserrat;
font-size: 19px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.profile-general-info{
  color: #000;
text-align: center;
font-family: Montserrat;
font-size: 11px;
margin-top: -10px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
 
.profile-edit-button{
  margin-top: -30px;
  color: #000;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
border-radius: 40px;
border: 1px solid #22762B;
background: #FFF;
}
.gs-data-collection-forms-div{
  position: relative;
  height: 60vh;
}
.mob-profile-edit-button{
  margin-top: -30px;
  color: #000;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
border-radius: 10px;
border: 1px solid #22762B;
background: #FFF;
padding: 7px 25px;
}
.mob-profile-logout-button{
  color: #000;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
margin-bottom: 0px;
font-weight: 500;
line-height: normal;
border-radius: 10px;
color: #22762B;
text-align: center;
font-family: Montserrat;
font-size: 16px;
margin-bottom: -20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 25px;
padding: 7px 25px;
}
.mob-profile-edit-button:hover{
  border: 1px solid #22762B;
  background: #FFF;
  color: #000;
}
.profile-edit-icon{
  height: 15px;
  margin-bottom:2px;
  margin-left: 1px;
}
.profile-edit-button:hover{
  border: 1px solid #22762B;
background: #FFF;
color: #000;
}
.profile-edit-button:focus{
  border: 1px solid #22762B;
background: #FFF;
color: #000;
}
.profile-edit-button:active{
  border: 1px solid #22762B;
background: #FFF;
color: #000;
}
.profile-qr-code{
  color: #000;
text-align: center;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
cursor: pointer;
margin-bottom: 25px;
font-weight: 600;
line-height: normal;
}
.profile-sign-out{
  padding-top: 8px;
  padding-left: 3px !important;
  padding-right: 3px !important;
  padding-bottom: 8px;
  margin-top: -10px;
}
.dashboard-landing-page{
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  height: 65vh;
}

.dashboard-landing-page-buttons{
  display: flex;
  height: 65vh;
  margin-left: 8px;
  justify-content: center;
  flex-direction: column;
}
.landing-page-buttons{
  margin-left: inherit !important;
  border-radius: 32.5px;
  background: radial-gradient(253.97% 95.38% at 26% 23.85%, #22762B 0%, #9FDA64 100%);
  box-shadow: -6px -11px 59.3px 0px rgba(255, 215, 72, 0.40) inset, 13px 9px 50.9px 0px rgba(34, 118, 43, 0.70) inset, 10px 14px 31.8px 0px rgba(34, 118, 43, 0.30);
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  color: white;
  width: 140px;
  padding: 7px 20px 7px 20px;
  margin-top: 0px;
  margin-left: auto;
  transition: 0.3s ease;
 }
.landing-page-button-active{
  margin-left: inherit;
  background: white !important;
  box-shadow: none !important;
  border-radius: 32.5px;
  font-weight: 600;
border: 1px solid #22762B;
color:black
}
.landing-page-button-active :hover{
  background: white !important;
  box-shadow: none !important;
  border-radius: 32.5px;
border: 1px solid #22762B;
color:black !important
}
.GS-Data-Collection-section{
  border-radius: 50px;
  width: fit-content;
  width: 200px;
  padding: 15px;
border: 2px solid #D3EAA3;
margin-right: 15px;
}
.mob-GS-Data-Collection-section{
  border-radius: 50px;
  width: fit-content;
  width: 230px;
  padding: 20px;
border: 2px solid #D3EAA3;
margin-right: 15px;
}
.GS-Data-Collection-Step-Number{
  color: #000;
text-align: center;
font-family: Montserrat;
font-size: 25px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.GS-Data-Collection-Step-Name{
  color: #000;
text-align: center;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.GS-Data-Collection-QR-Generator{
width: 120px;
cursor: pointer;
}
.GS-Data-Collection-disable-QR{
  width: 100px;
  margin-bottom: 20px;
  cursor:not-allowed
}
.GS-Data-Collection-Container{
  display: flex;
}
.mob-GS-Data-Collection-Container{
  display: flex;
  justify-content: center;
}
.GS-Data-Collection-Step-desc-text{
  color: #000;
font-family: "Montserrat Alternates";
font-size: 13px;
font-style: normal;
width: 180px;
font-weight: 400;
line-height: normal;
}
.Trail-form-access{
  display: flex;
  align-items: center;
  flex-direction: row;
  border: none;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  justify-items: center;
  border-radius: 42.5px;
  width: 100%;
color: #fff;
background: #F58839;
box-shadow: -26px -18px 39.9px 0px rgb(194 145 145 / 25%) inset, 19px 27px 55.4px 0px rgba(0, 0, 0, 0.25) inset, 13px 16px 24.7px 0px rgba(164, 133, 73, 0.50)
}
.Trail-form-access:hover{
  background: #F58839;
}
.Trail-form-access-disable{
  display: flex;
  align-items: center;
  flex-direction: row;
  border: none;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  justify-items: center;
  border-radius: 42.5px;
  width: 100%;
color: #fff;
background: #9d9d9d;
cursor: not-allowed !important;
    box-shadow: -26px -18px 39.9px 0px rgb(194 145 145 / 25%) inset, 19px 27px 55.4px 0px rgba(0, 0, 0, 0.25) inset, 13px 16px 24.7px 0px rgb(90 87 82 / 50%);
}
.Trail-form-access-disable:hover{
  background: #9d9d9d;
}
.qr-text{
  color: #000;
text-align: center;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 8px;
margin-bottom: 5px;
}
.gs-formname-text1{
  color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 11px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin: 5px;
margin-top: 10px !important;
}
.gs-formname-text2{
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
}
.gs-formname-div{
  line-height: 0.5rem;
  cursor: pointer;
  border-radius: 50px;
  margin-top: 25px;
border: 1px solid #FFF;
background: #22762B;
box-shadow: -26px -18px 39.9px 0px rgba(0, 0, 0, 0.25) inset, 19px 27px 55.4px 0px rgba(0, 0, 0, 0.25) inset, 9px 5px 24.7px 0px rgba(34, 118, 43, 0.40);
}
.gs-info-icon-div{
   position: absolute;
   top:80%;
   left: 90%;
}
.gs-info-icon{
  width: 60px;
  cursor: pointer;
}
.gs-continue-button{
  margin-top: -5px;
  float: right;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 9px;
  cursor: pointer;
  background: #ce5d0b;
  padding: 4px;
  color:#fff;
  border-radius: 10px;
}
.gs-access-granted{
  color: #22762B;
text-align: center;
font-family: Montserrat;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: normal;
}



.mob-logo{
  width: 200px;
}
.logo-mob-row{
  justify-content: center;
  margin-top: 40px;
}
.css-b62m3t-container{
  width: auto;
}
.next-button{
  margin-top: 30px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  width: 100px;
}
.logo-mob-row-lang{
  justify-content: center;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
}
.footer-mob-links-left{
  padding-left: 0px;
  padding-right: 0px;
}

.footer-mob-links-right{
  padding-left: 0px;
  padding-right: 0px;
}

.mob-home-main-section{
  min-height:94vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header-mob-logo{
  width: 150px;
}
.mob-header-section{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.mob-sigin-button{
  width: auto;
  margin-right: 10px;
}
.mob-landingpage-title{
  color: #000;
text-align: center;
padding: 50px;
font-family: "Bebas Neue";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.54px;
margin-bottom: 0px;
}
.mob_section_home_mid_section{
  align-items: center !important;
  justify-content: center;
  height: 320px !important;
  width: 320px !important;
}

.counter-title-mob{
color: #FFF !important;
text-align: center !important;
font-family: "Bebas Neue" !important;
font-size: 22px !important;
font-style: normal !important;
font-weight: 400 !important;
opacity: 1 !important;
line-height: normal !important;
letter-spacing: 0.96px !important;
}
.mob-lower-header-icon-background-green{
  border-radius: 50px;
  border: 1px solid #FFF;
  background: #22762B;
  padding: 5px;
  margin-right: 15px;
  width: fit-content;
  
}
.mob-lower-header-icon-background-white{
  border-radius: 25px;
  border: 1px solid #22762B;
  background: #FFF;
  padding: 5px;
  margin-right: 15px;
  width: fit-content;
}
.mob-lower-header-icon{
  width: 25px;
}
.mob-lower-header-icon-background{
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mob-event-div{
  border-radius: 50px;
border: 1px solid #D3EAA3;
background: linear-gradient(223deg, #FFF 17.76%, #D3EAA3 131.1%);
margin-top: 15px;
}
.mob-event-div-date{
  color: #22762B;
font-family: Montserrat;
font-size: 64px;
font-style: normal;
text-align: center;
font-weight: 600;
line-height: normal;
margin-bottom: -15px;
}
.mob-event-div-month{
  color: #22762B;
font-family: Montserrat;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.mob-event-div-name{
  color: #22762B;
text-align: center;
font-family: Montserrat;
font-size: 26px;
font-style: normal;
font-weight: 700;
border-bottom: 3px solid #D3EAA3;
padding-bottom: 10px;
line-height: normal;
}
.mob-event-div-marker{
  text-align: center;
  width: 15px;
  margin-bottom: 5px;
}
.mob-event-div-place-name{
  color: #22762B;
  text-align: center;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.mob-event-main-div{
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* height: 80vh;
  overflow-y: scroll; */
  /* padding-left: 30px;
  padding-right: 30px; */
}
.mob-login-section-container{
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mob-profile-image{
  width: 200px;
  height: 200px;
  border-radius: 400px !important;
}
.mob-profile-image-circle{
  display: flex;
  justify-content: center;
}
.mob-profile-name-info{
  color: #000;
text-align: center;
font-family: Montserrat;
font-size: 20px;
margin-top: 5px;
margin-bottom: 0px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.mob-profile-general-info{
  color: #000;
text-align: center;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.mob-counter-div{
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-evenly;
}
.mob-counter-button-filled{
  border-radius:150px;
  padding: 13px;
  color:white;
  width: 50px;
background: radial-gradient(253.97% 95.38% at 26% 23.85%, #22762B 0%, #9FDA64 100%);
box-shadow: -6px -11px 59.3px 0px rgba(255, 215, 72, 0.40) inset, 13px 9px 50.9px 0px rgba(34, 118, 43, 0.70) inset, 3px 4px 14.7px 0px rgba(34, 118, 43, 0.30);
}
.mob-counter-button-blank{
  border-radius:150px;
  padding: 13px;
  color:white;
  color: #000;
text-align: center;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
  width: 50px;
  border: 1px solid #22762B;
/* background: radial-gradient(253.97% 95.38% at 26% 23.85%, #22762B 0%, #9FDA64 100%); */
/* box-shadow: -6px -11px 59.3px 0px rgba(255, 215, 72, 0.40) inset, 13px 9px 50.9px 0px rgba(34, 118, 43, 0.70) inset, 3px 4px 14.7px 0px rgba(34, 118, 43, 0.30); */
}
.mob-counter-button-collect{
  border-radius: 32.5px;
border: 1px solid #22762B;
background: #FFF;
color: #000;
text-align: center;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding: 5px 10px 5px 10px;
display: flex;
align-items: center;
}
.mob-counter-button-collect-visible{
  border-radius: 32.5px;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 5px 10px 5px 10px;
  display: flex;
  align-items: center;
  background: radial-gradient(253.97% 95.38% at 26% 23.85%, #22762B 0%, #9FDA64 100%);
box-shadow: -6px -11px 59.3px 0px rgba(255, 215, 72, 0.40) inset, 13px 9px 50.9px 0px rgba(34, 118, 43, 0.70) inset, 3px 4px 14.7px 0px rgba(34, 118, 43, 0.30);
}
.compaigns-main-col-1-row{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.compaigns-main-col-1-text-1{
  width: fit-content;
  font-family: Montserrat;
font-size: 70px;
font-style: normal;
font-weight: 600;
line-height: normal;
background: linear-gradient(0deg, rgba(34, 118, 43, 0.10) 0%, rgba(63, 220, 80, 0.00) 113.01%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.compaigns-main-col-1-text-2-div{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.compaigns-main-col-1-text-2{
  width: fit-content;
  color: #22762B;
font-family: Montserrat;
font-size: 70px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.compaigns-main-col-1-total-compaigns{
  color: #000;
text-align: center;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
margin-top: -20px;
font-weight: 300;
padding-left: 6px;
border-left: 7px solid #e4f7e6;
line-height: normal;
}
.compaigns-main-col-2-heading{
  color: #22762B;
font-family: Montserrat;
font-size: 22px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.compaigns-main-col-2-desc{
  color: #000;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.compaigns-main-col-2-div1-bg{
  width: 100%;
}
.compaigns-main-col-2-div1-bg-text1{
  color: #FFF;
text-align: right;
font-family: Montserrat;
font-size: 56px;
font-style: normal;
font-weight: 600;
margin-left: 46px;
margin-top: 5px;
margin-bottom: 0px;
line-height: normal;
width: fit-content;
}
.compaigns-main-col-2-div1{
  position: relative;
  width: 450px;
}
.compaigns-main-col-2-div1-bg-div{
  position: absolute;
  z-index: 100;
  width: 100%;
  display: flex;
  top:0;
  height: 135px;
  align-items: center;
}
.compaigns-main-col-2-div1-bg-div1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
  border-right: 2px solid white;
  height: 100%;
  border-right-style:dashed;
}
.compaigns-main-col-2-div1-bg-text2{
  margin-left: 46px;
  width: fit-content;
  margin-top: -7px;
  color: #FFF;
text-align: right;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.compaigns-main-col-2-div1-bg-div2-text1{
  color: #FFF;
text-align: right;
font-family: Montserrat;
font-size: 23px;
font-style: normal;
font-weight: 400;
margin-bottom: 5px;
line-height: normal;
}
.compaigns-main-col-2-div1-bg-div2-text2{
  color: #FFF;
text-align: right;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
width: fit-content;
}
.compaigns-main-col-2-div1-bg-div2{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}
.compaigns-main-col-2-row{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  cursor: pointer;
}
.compaigns-main-col-2-row::-webkit-scrollbar{
  display: none;
}
.compaigns-main-row{
  align-items: center;
  margin-top: 10px;
}
.compaign-modal-body{
  border-radius: 50px;
border: 2px solid #22762B;
background: #FFF;
}
.compaign-modal-input-text{
  color: #000;
text-align: center;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.compaign-modal-input{
  border-radius: 32.5px;
border: 2px solid #22762B;
background: #FFF;
}
.compaign-modal-submit-button{
  border: 0px;
  padding: 7px 30px 7px 30px;
  border-radius: 12.5px;
  color: white;
  margin-top: 10px;
background: radial-gradient(253.97% 95.38% at 26% 23.85%, #22762B 0%, #9FDA64 100%);
box-shadow: -6px -11px 59.3px 0px rgba(255, 215, 72, 0.40) inset, 13px 9px 50.9px 0px rgba(34, 118, 43, 0.70) inset, 10px 14px 31.8px 0px rgba(34, 118, 43, 0.30);
}
.gap-table-col-1-div-1{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.gap-table-col-1-div-1-button{
  border-radius: 22.5px;
  font-size: 11px;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 6px;
  padding-bottom: 6px;
border: 1px solid #22762B;
background: #FFF;
color: #A8A8A8;
text-align: center;
font-family: Montserrat;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.gap-table-col-1-div-1-button-disable{
  color: #A8A8A8;
text-align: center;
font-family: Montserrat;
background: none;
font-size: 12px;
border: none;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.gap-table-col-1-div-1-title{
  color: #22762B;
font-family: Montserrat;
font-size: 23px;
font-style: normal;
font-weight: 800;
line-height: normal;
padding: 25px 20px 5px 35px;
margin-left: 35px;
margin-bottom: 5px;
}
.gap-table-col-1-div-1-desc{
  color: #000;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 5px 20px 5px 35px;
margin-left: 35px;
}
.gap-table-col-2-div-1-image{
  width: 95%;
}
.gap-table-col-2-div-1-title{
  color: #000;
text-align: center;
font-family: Montserrat;
font-size: 14px;
margin-top: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;
width: fit-content;
}
.gap-table-col-2-div-1-desc{
  color: #000;
font-family: Montserrat;
font-size: 11px;
font-style: normal;
font-weight: 300;
line-height: normal;
width: fit-content;
width: 200px;
padding-left: 10px;
}
.gap-table-col-2-div-1-button{
  border-radius: 15px;
  padding: 8px 36px 8px 36px;
border: 1px solid #22762B;
box-shadow: 1px 1px 25.6px 0px rgba(34, 118, 43, 0.25);
color: #8C8C8C;
font-family: Montserrat;
font-size: 11px;
font-style: normal;
font-weight: 500;
width: fit-content;
line-height: normal;
}
.gap-table-col-2-div-1{
  width: 350px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.gap-table-col-2-div-main{
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
.gap-table-col-2-div-main::-webkit-scrollbar{
  display: none;
}
.nfilms-col1-div1{
  padding: 10px;
  width: 90%;
}
.nfilms-col1-main-video{
  width: 100%;
  border-radius: 30px;
  /* border: 1px solid; */
}
.video-container {
  width: 100%;
  border-radius: 30px;
  display: inline-block; /* Ensures the wrapper takes the size of the video */
}
video { 
  border-radius: 10px; 
  overflow: hidden; 
} 
.home-activities-text-box{
  display: absolute;
}
.pfg-updated-game1-image{
  width: 100%;
  margin-top: -60px;
}
.pfg-updated-game1-title{
  color: #000;
text-align: center;
font-family: "Montagu Slab";
font-size: 16px;
margin-bottom: 65px;
font-style: normal;
width: fit-content;
font-weight: 600;
line-height: normal;
}
.pfg-updated-game1{
  width: 230px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pfg-updated-game1-button{
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 60%;
  font-size: 14px;
  padding: 3px 55px;
background: #22762B;
color: white;
box-shadow: 6px 8px 12.4px 0px rgba(0, 0, 0, 0.15), -26px -18px 39.9px 0px rgba(0, 0, 0, 0.25) inset, 19px 27px 55.4px 0px rgba(0, 0, 0, 0.25) inset;
}
.pfg-updated-game1-desc{
  color: #000;
font-family: Montserrat;
margin-top: 10px;
text-align: left;
font-size: 11px;
width: 100%;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.pfg-updated-div{
  border-radius: 50px;
  border: 1px solid #D3EAA3;
  padding: 0px 20px;
}
.pfg-updated-row{
  flex-direction: row;
 align-items: center;
 justify-content: center;
 height: 70vh; 
}
.pfg-updated-row-2{
  align-items: center;
 justify-content: center;
}
.gap-data-form-title{
  color: #22762B;
font-family: Montserrat;
font-size: 21px;
padding-left: 20px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.gap-data-form-desc{
  color: #000;
font-family: Montserrat;
font-size: 14px;
padding-left: 20px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.gap-data-form-input{
  border-radius: 40px;
border: 1px solid #22762B;
background: #FFF;
width: 100%;
margin-bottom: 15px;
padding-left: 15px;
height: 35px;
}
.gap-data-form-input::placeholder{
  color: #464646;
font-family: Montserrat;
font-size: 13px;
padding-left: 5px;
font-style: normal;
font-weight: 500;
line-height: normal;
border: none;
outline: none;
}
.gap-data-form-input:focus{
  outline: none;
  border: none;
}
.gap-form-row{
  border-radius: 15px;
border: 1px solid #FFF;
padding: 15px;
width: 350px;
background: linear-gradient(157deg, #73AB78 14.59%, #CBEECF 80.66%);
box-shadow: 1px 1px 25.6px 0px rgba(34, 118, 43, 0.25);
margin-bottom: 30px;
}
.contact-form-row{
  border-radius: 15px;
  border: 1px solid #FFF;
  padding: 15px;
  width: 250px;
  background: linear-gradient(157deg, #73AB78 14.59%, #CBEECF 80.66%);
  box-shadow: 1px 1px 25.6px 0px rgba(34, 118, 43, 0.25);
  margin-bottom: 30px;
}
.contact-form-row-1{
  border-radius: 15px;
  border: 1px solid #FFF;
  padding: 15px;
  width: 250px;
  background: linear-gradient(157deg, #73AB78 14.59%, #CBEECF 80.66%);
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  box-shadow: 1px 1px 25.6px 0px rgba(34, 118, 43, 0.25);
  /* margin-bottom: 30px; */
}
.contact-us-promt-leftside{
  background-color: #fff;
  padding-left: 45px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding-top: 20px;
}
.gap-data-form-button{
  border-radius: 20px;
border: 1px solid #22762B;
background: #22762B;
padding: 6px 25px;
color: #FFF;
font-family: Montserrat;
font-size: 11.5px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.gap-data-form-title-1{
  color: #FFF;
font-family: Montserrat;
font-size: 21px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.comming-soon-stay-tuned{
  color: #22762B;
text-align: center;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 275;
margin-bottom: 5px;
line-height: normal;
}
.comming-soon{
  color: #22762B;
font-family: Montserrat;
font-size: 34px;
text-align: center;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.contact-us-title{
  color: #22762B;
font-family: Montserrat;
font-size: 21px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.contact-us-desc{
  color: #000;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.contact-us-link{
  color: #000;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 400;
margin-bottom: 0px;
line-height: normal;
}
.contact-us-icons{
  width: 15px;
  margin-right: 10px;
}
.contact-us-icons-links-div{
  display: flex;
  align-items: center;
}
.api-error{
  color: red;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: -10px;
}

@media screen  and (min-width:1300px )  {
  .header_logo {
    width: 170px;
  }
  .headerLinkActive {
    color: #22762b;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    border: 1px solid #22762b;
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
    margin-right: 46px;
  }
  .headerLink {
    color: #000000;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-right: 46px;
  }
  .navbar-user-profile{
    width: 46px;
  transition: 0.3s ease;
  }
  .home_mid_section {
    height: 480px;
    display: flex;
    align-items: center;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    width: 480px;
    background-image: url("./assets/home/test.jpg");
    background-size: cover; /* This will cover the entire background */
    background-position: center;
    z-index: 1;
    border-radius: 250px;
    box-shadow: rgba(34, 118, 43, 0.4) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  .home_mid_section_1{
    height: 480px;
    display: flex;
    align-items: center;
    align-items: flex-end;
    padding-left: 20px;
    padding-right: 20px;
    width: 480px;
    background-size: cover; /* This will cover the entire background */
    background-position: center;
    z-index: 1;
    border-radius: 250px;
    box-shadow: rgba(34, 118, 43, 0.4) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  .dynamic-counter {
    width: 101px;
    margin-top: 50px;
    margin-bottom: 40px;
}
.counter-title {
  position: absolute;
  opacity: 1 !important;
  top: 20%;
  font-size: 22px;
}
.home-mid-section-text {
  font-family: "Bebas Neue";
  font-size: 27px;
  letter-spacing: 0.05rem;
  padding: 2px 42px;
  color: white;
}
.home-mid-section-text_1{
  font-family: "Bebas Neue";
    font-size: 27px;
    letter-spacing: 0.05rem;
    padding: 2px 30px;
    color: white;

}
.homeButton {
  border-radius: 32.5px;
  background: radial-gradient(253.97% 95.38% at 26% 23.85%, #22762B 0%, #9FDA64 100%);
  box-shadow: -6px -11px 59.3px 0px rgba(255, 215, 72, 0.40) inset, 13px 9px 50.9px 0px rgba(34, 118, 43, 0.70) inset, 10px 14px 31.8px 0px rgba(34, 118, 43, 0.30);
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border-radius: 20px;
  border: none;
  width: 180px;
  padding: 7px 20px 7px 20px;
  margin-top: 25px;
  margin-left: auto;
  transition: 0.3s ease;
  z-index: 100;
}
.homeButtonright {
  border-radius: 32.5px;
  background: radial-gradient(253.97% 95.38% at 26% 23.85%, #22762B 0%, #9FDA64 100%);
  box-shadow: -6px -11px 59.3px 0px rgba(99, 95, 81, 0.4) inset, 13px 9px 50.9px 0px rgba(34, 118, 43, 0.70) inset, 10px 14px 31.8px 0px rgba(34, 118, 43, 0.30);
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border-radius: 20px;
  border: none;
  width: 180px;
  padding: 7px 20px 7px 20px;
  margin-top: 25px;
  margin-left: 0;
  z-index: 100;
  transition: margin-left 0.3s ease-in; /* Added transition property */
}
.signIn-button{
  color: #000;
  margin-top: auto;
  margin-bottom: auto;
text-align: center;
font-family: Montserrat;
font-size: 16px;
padding: 5px 13px;
font-style: normal;
font-weight: 600;
cursor: pointer !important;
line-height: normal;
  border-radius: 32.5px;
border: 1px solid #000;
background: radial-gradient(253.97% 95.38% at 26% 23.85%, #D2D2D2 0%, #FFF 100%);
box-shadow: -6px -11px 59.3px 0px rgba(255, 255, 255, 0.40) inset, 13px 9px 50.9px 0px rgba(196, 240, 201, 0.70) inset, 4px 3px 10.8px 0px rgba(34, 118, 43, 0.30);
}
.footer-links-left {
  color: white;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  line-height: 0.45rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer-links-right {
  color: white;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  line-height: 0.45rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.footer-icon-img{
  width: 26px;
}
.science-comp-text{
  color: #22762B;
  margin-top: auto;
font-family: Montserrat;
font-size: 29px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.science-comp-desc{
  color: #000;
font-family: Montserrat;
font-size: 14.5px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.science-comp-button-updated{
  font-size: 14px;
  border-radius: 50px;
  width: 195px;
border: 1px solid #FFF;
background: #22762B;
margin-top: 5px;
box-shadow: 6px 8px 12.4px 0px rgba(0, 0, 0, 0.15), -26px -18px 39.9px 0px rgba(0, 0, 0, 0.25) inset, 19px 27px 55.4px 0px rgba(0, 0, 0, 0.25) inset;
}
.science-comp-image{
  height: 180px;
  width: 186px;
  object-fit: cover;
  border-radius: 20px;
  margin-top: 23px;
}
.science-comp-image-hovered{
  height: 180px;
  width: 186px;
  object-fit: cover;
  border-radius: 20px;
  margin-right: 5px;
}
.science-card-desc{
  color: #000;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 5px;
}
.team-page-heading{
  font-size: 19px;
}
.team-page-title {
  color: #22762b;
  font-size: 19px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  /* border: 0.02rem solid #22762b; */
  border-radius: 7px;
border: 0.02rem solid #22762B;
box-shadow: 1px 1px 5.6px 0px rgba(34, 118, 43, 0.25);
  padding: 6px 8px 6px 8px;
  margin-right: 15px;
}
.ncount-journey-description {
  font-size: 18px;
  font-family: Montserrat;
}
.team-image-box {
  position: relative;
  margin-bottom: -30px;
  box-sizing: border-box;
  border: 2px solid #ffffff;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(9px 10px 25.6px rgba(34, 118, 43, 0.25));
  border-radius: 15px;
  z-index: 10;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  margin-right: 70px;
}

.team-image {
  border: 0.07rem solid #037711;
  height: 90px;
  width: 90px;
  filter: drop-shadow(9px 10px 25.6px rgba(34, 118, 43, 0.25));
  border-radius: 10px;
}
.team-desc-box {
  z-index: 0;
  background: linear-gradient(
    127.79deg,
    rgba(34, 118, 43, 0.3) 23.62%,
    rgba(255, 255, 255, 0.3) 127.09%
  );
  border: 2px solid #ffffff;
  box-shadow: 1px 1px 25.6px rgba(34, 118, 43, 0.25);
  border-radius: 15px;
  width: 135px;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.4s, width 0.4s, height 0.4s;
  position: absolute; /* Relative to the containing block */
  overflow: hidden;
  transform-origin: top center; /* Pivot from the top center */
  padding-top: 40px; /* Ensure space for the image */
}
.team-member-title{
  font-size:13px
}
.team-member-desc{
  font-size:11px
}
.team-member-title-partner{
  font-size: 13px;
}
.team-member-desc-partner{
  font-size: 11px;
}
.login_text_heading {
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  font-weight: 700;
  margin-top: 30px;
}
.login-pin-input {
  margin-top: 35px;
  border-radius: 25px;
  border: 1.35px solid #079015;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  text-align: center;
}
.login-section-container {
  padding: 10px 30px;
  /* margin-top: 30px; */
  height: 73vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-submit {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 18px;
  border: none;
  width: 200px;
  margin-top: 35px;
  border-radius: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: radial-gradient(#22762b, #76a844);
}
.login-forgot-password {
  font-family: "Montserrat", sans-serif;
  color: #22762b;
  font-weight: 600;
  font-size: 15px;
  margin-top: 40px;
}
.login-new-user-register {
  font-family: "Montserrat", sans-serif;
  color: #22762b;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.login-pin-input::placeholder {
  font-family: "Montserrat", sans-serif;
  color: #a8a8a8;
  font-size: 15px;
  font-weight: 500;
}
.api-error{
  color: red;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: -10px;
}
.pfg-updated-game1-title{
  color: #000;
text-align: center;
font-family: "Montagu Slab";
font-size: 20px;
margin-bottom: 65px;
font-style: normal;
width: fit-content;
font-weight: 600;
line-height: normal;
}
.pfg-updated-game1{
  width: 280px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pfg-updated-game1-button{
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 60%;
  font-size: 16.5px;
  padding: 3px 65px;
background: #22762B;
color: white;
box-shadow: 6px 8px 12.4px 0px rgba(0, 0, 0, 0.15), -26px -18px 39.9px 0px rgba(0, 0, 0, 0.25) inset, 19px 27px 55.4px 0px rgba(0, 0, 0, 0.25) inset;
}
.pfg-updated-game1-desc{
  color: #000;
font-family: Montserrat;
margin-top: 20px;
text-align: left;
font-size: 12.5px;
width: 100%;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.landing-page-buttons{
  margin-left: inherit !important;
  border-radius: 32.5px;
  background: radial-gradient(253.97% 95.38% at 26% 23.85%, #22762B 0%, #9FDA64 100%);
  box-shadow: -6px -11px 59.3px 0px rgba(255, 215, 72, 0.40) inset, 13px 9px 50.9px 0px rgba(34, 118, 43, 0.70) inset, 10px 14px 31.8px 0px rgba(34, 118, 43, 0.30);
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  color: white;
  width: 180px;
  padding: 8px 25px 8px 25px;
  margin-top: 5px;
  margin-left: auto;
  transition: 0.3s ease;
 }
 .profile-image-circle{
  width: min-content;
    border-radius: 109.5px;
  border: 1px solid #22762B;
  background: #FFF;
  margin-top: 10px;
  padding: 5px;
  box-shadow: 5px 2px 12px 0px rgba(34, 118, 43, 0.20);
  }
  .profile-image{
    width: 105px;
    height: 105px;
    border-radius: 50px;
  }
  .profile-input{
    height: 40px;
    text-align: start;
    width:290px;
    padding-top: 0px !important;
    margin-top: 12px !important;
    font-size: 16px;
  }
  .profile-textarea{
    margin-top: 12px;
    border-radius: 15px !important;
    height: 60px !important;
    width: 290px !important;
    margin-bottom: -6px;
  }
  .css-13mqk8s-control{
    margin-left: -45px;
  }
  .profile-update-button{
    border-radius: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
  border: 1px solid #22762B;
  background: #22762B;
  font-size: 16px;
  padding-left: 18px;
  padding-right: 18px;
  }
  .profile-section{
    overflow: scroll;
    border-radius: 15px;
  border: 2px solid #FFF;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(157deg, rgba(34, 118, 43, 0.30) 14.59%, rgba(255, 255, 255, 0.30) 80.66%);
  box-shadow: 1px 1px 25.6px 0px rgba(34, 118, 43, 0.25);
  }
  .landing-page-button-active{
    margin-left: inherit;
    background: white !important;
    box-shadow: none !important;
    border-radius: 32.5px;
    font-weight: 600;
  border: 1px solid #22762B;
  color:black
  }
  .GS-Data-Collection-section{
    border-radius: 50px;
    width: fit-content;
    width: 230px;
    padding: 15px 25px;
  border: 2px solid #D3EAA3;
  margin-right: 15px;
  }
  .GS-Data-Collection-Step-Number{
    color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .GS-Data-Collection-Step-Name{
    color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .GS-Data-Collection-disable-QR{
    width: 130px;
    margin-bottom: 30px;
    cursor:not-allowed
  }
  .GS-Data-Collection-QR-Generator{
    width: 150px;
    cursor: pointer;
    margin-bottom: 10px;
    }
    .GS-Data-Collection-Step-desc-text{
      color: #000;
    font-family: "Montserrat Alternates";
    font-size: 15px;
    font-style: normal;
    width: 180px;
    font-weight: 400;
    line-height: normal;
    }
    .gs-continue-button{
      margin-top: -5px;
      float: right;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 12px;
      cursor: pointer;
      background: #ce5d0b;
      padding: 8px;
      color:#fff;
      border-radius: 10px;
    }
    .dashboard-nav-tabs{
      font-size: 21px;
      font-weight: 600;
    }
    .gs-data-collection-forms-div{
      position: relative;
      height: 80vh;
    }
}
.legal-button-toggle{
padding-left: 30px;
}
.legal-button-active{
  font-size: 14px;
  color:#22762b;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 7px;
}
.legal-button{
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 7px;
  cursor: pointer;
}

.legal-docs-space{
  overflow-x: hidden;
}
.privacy-policy-text{
  text-align: left;
  color: #000;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
word-wrap: break-word;
  overflow-wrap: break-word; 
}
.comming-soon-gif{
  width: 70%;
}
.comming-soon-gif-text{
  color: #22762B;
  text-align: center;
  font-family: Montserrat;
font-size: 20px;
font-style: normal;
width: 60%;
font-weight: 500;
line-height: normal;
margin-top: -20px;
}

.my-collection-main-div{
  height:65vh;
  overflow-y:scroll
}
.my-collection-main-div::-webkit-scrollbar{
  display: none;
}
.leaflet-container {
  width: 100wh; 
  height: 60vh;
}
.customMarker {
  background-color: transparent;
}

.customMarker span {
  color: #fff;
  font-size: 12px; /* Adjust font size to fit the number inside */
  font-weight: bold;
}
.info-legend{
  padding: 10px;
  background: #acb5ab;
  max-height: 200px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.leaflet-control-layers-list{
  text-align: left !important;
}
/* .info-legend::-webkit-scrollbar{
  width: 10px;
} */
.nfold-main-row{
  height: 72vh;
  align-items: flex-end;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: hidden;
  overflow-x:hidden;
  width: 100vw;
}
.nfold-tools-tab-div{
  padding-top: 30px;
  max-height: 60vh;
  overflow-y: scroll;
  width: 70vw;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 30px;
  overflow-x: scroll;
}
.tab-content .tab-pane.active.nfold-tools-tab {
  display: flex;
  align-items: flex-end;
  height: 60vh;
}
.nfold-tools-tab>.active{
  display: flex;
  align-items: flex-end;
}
.nfold-tools-tab-div::-webkit-scrollbar{
  display: none;
}
.nfold-community-tab-desc{
  color: #000;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
}